import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { AttachFile, Delete, Search } from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import RouterPrompt from '../../components/RouterPrompt';
import { del, get, post, put } from '../../shared/api';
import { getDateFormValue, getErrorMessage } from '../../shared/helpers';
import { CONTAINER_STATUSES, ORDER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../shared/contants';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from '../Login/components/LoginForm.module.scss';
// import galleryStyles from './OrderForm.module.scss';

const disabledFieldsWarehouse = [
  'vin',
  'stockNumber',
  'auctionName',
  'auctionLocation',
  'username',
  'make',
  'model',
  'year',
  'keysPresent',
  'warehouse',
];

const OrderForm = () => {
  const { control, formState, handleSubmit, reset, getValues, setValue, watch } = useForm();
  const [users, setUsers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [auctionsData, setAuctionsData] = useState([]);
  const [auctionLocations, setAuctionLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpload, setErrorMessageUpload] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateSuccessful, setIsCreateSuccessful] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingContainers, setIsLoadingContainers] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  // const [imageToDelete, setImageToDelete] = useState('');
  const [fileToDelete, setFileToDelete] = useState('');
  // const [isAddImageDialogOpen, setIsAddImagesDialogOpen] = useState(false);
  const [isAddFileDialogOpen, setIsAddFileDialogOpen] = useState(false);
  // const [vehicleImages, setVehicleImages] = useState([]);
  const [vehicleFiles, setVehicleFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  // const [currentImages, setCurrentImages] = useState(null);
  const [documentToUploadType, setDocumentToUploadType] = useState();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();
  const { isDirty } = formState;
  const auctionId = watch('auctionName');
  const currentUser = useSelector(selectUser);

  const usersData = users.filter(user => user.role !== ROLE_WAREHOUSE);
  const warehousesData = users.filter(user => user.role === ROLE_WAREHOUSE);

  const disabledFields = currentUser.role === ROLE_WAREHOUSE ? disabledFieldsWarehouse : [];

  useEffect(() => {
    loadUsers();
    //loadContainers();
    loadAuctionsData();

    return () => {
      reset({
        vin: '',
        stockNumber: '',
        auctionName: '',
        auctionLocation: '',
        status: 7,
        make: '',
        model: '',
        year: '',
        keysPresent: '',
        username: '',
        warehouse: '',
        placeOfDelivery: '',
        container: '00000000-0000-0000-0000-000000000000',
        expectedDate: '',
        note: '',
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      loadOrderDetails(id);
      // loadImages();
      loadFiles();
    } else {
      reset({
        vin: '',
        stockNumber: '',
        auctionName: '',
        auctionLocation: '',
        status: 7,
        make: '',
        model: '',
        year: '',
        keysPresent: '',
        username: '',
        warehouse: '',
        placeOfDelivery: '',
        container: '00000000-0000-0000-0000-000000000000',
        expectedDate: '',
        note: '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (auctionsData.length === 0) {
      return;
    }

    if (auctionId) {
      const currentAuction = auctionsData.find(auction => auction.id === auctionId);

      if (currentAuction && currentAuction.locations) {
        setAuctionLocations(currentAuction.locations.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        }));
      } else {
        setValue('auctionLocation', '');
        setAuctionLocations([]);
      }
    } else {
      setValue('auctionLocation', '');
      setAuctionLocations([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId, auctionsData]);

  const loadAuctionsData = () => {
    get('/auctions')
      .then((data) => {
        if (Array.isArray(data)) {
          setAuctionsData(data.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadContainers = () => {
    setIsLoadingContainers(true);

    let ordersUrl = '/containers/my';
    if (currentUser.role === ROLE_ADMIN) {
      ordersUrl = '/containers';
    }

    get(ordersUrl)
      .then((containersData) => {
        if (Array.isArray(containersData)) {
          setContainers(containersData.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoadingContainers(false);
      });
  };

  const loadUsers = () => {
    setIsLoadingUsers(true);

    get('/identity/users/all')
      .then((usersData) => {
        if (Array.isArray(usersData)) {
          setUsers(usersData.sort(function (a, b) {
            return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
          }));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  };

  const loadOrderDetails = (orderId) => {
    setIsLoadingOrder(true);

    get(`/vehicles/id/${orderId}`)
      .then((data) => {
        reset({
          ...data,
          expectedDate: getDateFormValue(data.expectedDate),
          auctionName: data.auctionName?.id,
          auctionLocation: data.auctionLocation?.id,
          keysPresent: data.keysPresent ? 'true' : 'false',
          status: ORDER_STATUSES.find(status => status.key === data?.status)?.value,
          container: data.container?.id || '00000000-0000-0000-0000-000000000000',
          username: data.userName,
          year: +data.year,
        });
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoadingOrder(false);
      });
  };

  // const loadImages = () => {
  //   get(`/images/get/${id}`)
  //     .then((images) => {
  //       if (images && Array.isArray(images)) {
  //         setVehicleImages(images.filter(image => !!image));
  //       }
  //     })
  //     .catch((error) => {
  //       addToast(getErrorMessage(error), { appearance: 'error' });
  //     });
  // };

  const loadFiles = () => {
    get(`/pdf/get/${id}`)
      .then((files) => {
        if (files && Array.isArray(files)) {
          setVehicleFiles(files.filter(fileName => !!fileName));
        } else {
          const attachments = [];

          Object.entries(files).forEach(([fileType, pdfs]) => {
            let directory = '';

            switch (fileType) {
              case 'billOfLading': {
                directory = 'Bill of Lading';
                break;
              }
              case 'billOfSale': {
                directory = 'Bill of Sale';
                break;
              }
              case 'vehicleTitles': {
                directory = 'Vehicle Title';
                break;
              }
              default: {
                directory = 'Not specified';
              }
            }

            pdfs.forEach((fileData) => {
              attachments.push({
                ...fileData,
                displayName: `${fileData.title} (${directory})`
              })
            });
          });

          setVehicleFiles(attachments);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  // const uploadImage = () => {
  //   setIsLoading(true);
  //   setErrorMessageUpload('');
  //
  //   if (currentImages && currentImages.length) {
  //     let formData = new FormData();
  //     currentImages.forEach(image => {
  //       formData.append('files', image);
  //     });
  //
  //     post(`/images/upload/${id}`, formData)
  //       .then((response) => {
  //         if (response) {
  //           setIsAddImagesDialogOpen(false);
  //           setCurrentImages(null);
  //           addToast('Images uploaded successfully!', { appearance: 'success' });
  //           loadImages();
  //         }
  //       })
  //       .catch((error) => {
  //         setErrorMessageUpload(getErrorMessage(error));
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   } else {
  //     setIsLoading(false);
  //     setErrorMessageUpload('You must select an image!');
  //   }
  // };

  const uploadFile = () => {
    setIsLoading(true);
    setErrorMessageUpload('');

    if (currentFile && documentToUploadType) {
      let formData = new FormData();
      formData.append('files', currentFile);

      post(`/pdf/upload/${id}?typeOfFile=${documentToUploadType}`, formData)
        .then((response) => {
          if (response) {
            setIsAddFileDialogOpen(false);
            setCurrentFile(null);
            addToast('File successfully uploaded!', { appearance: 'success' });
            loadFiles();
          }
        })
        .catch((error) => {
          setErrorMessageUpload(getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setErrorMessageUpload('You must select document type and a file!');
    }
  };

  // const deleteImage = () => {
  //   del(`/images/${id}/${imageToDelete}`)
  //     .then((response) => {
  //       if (response) {
  //         addToast(response, { appearance: 'success' });
  //         setImageToDelete('');
  //         loadImages();
  //       }
  //     })
  //     .catch((error) => {
  //       addToast(getErrorMessage(error), { appearance: 'error' });
  //     });
  // };

  const deleteFile = () => {
    del(`/pdf/${id}/${fileToDelete}`)
      .then((response) => {
        if (response) {
          addToast(response, { appearance: 'success' });
          setFileToDelete('');
          loadFiles();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  // eslint-disable-next-line no-unused-vars
  const populateCarInfo = (event) => {
    event.preventDefault();
    const { vin } = getValues();

    if (vin) {
      get(`vehicles/decode/${vin}`)
        .then((data) => {
          const { make, model, year } = data;
          setValue('make', make || '');
          setValue('model', model || '');
          setValue('year', +year || '');
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        });
    } else {
      addToast('Please, enter VIN number first!', { appearance: 'warning' })
    }
  };

  // const closeImageUploadDialog = () => {
  //   setIsAddImagesDialogOpen(false);
  //   setCurrentImages(null);
  //   setErrorMessageUpload('');
  // };

  const closeFileUploadDialog = () => {
    setIsAddFileDialogOpen(false);
    setCurrentFile(null);
    setErrorMessageUpload('');
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');

    if (id) {
      await put('/vehicles', {
        ...data,
        id,
        year: data.year.toString(),
        keysPresent: data.keysPresent === 'true',
        expectedDate: data?.expectedDate?.length > 0 ? `${data.expectedDate}` : ''
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast('Order updated successfully!', { appearance: 'success' });
            setIsSuccessful(true);
            history.push('/orders');
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await post('/vehicles', {
        ...data,
        id,
        year: data.year.toString(),
        keysPresent: data.keysPresent === 'true',
        expectedDate: data?.expectedDate?.length > 0 ? `${data.expectedDate}` : ''
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast('Order created!', { appearance: 'success' });
            setIsSuccessful(true);
            setIsCreateSuccessful(true);

            history.push('/orders');
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // const selectImages = (event) => {
  //   const numberOfSelectedImages = event.target.files?.length || 0;
  //   const imagesToUpload = [];
  //
  //   if (numberOfSelectedImages) {
  //     for (let index = 0; index < numberOfSelectedImages; index++) {
  //       imagesToUpload.push(event.target.files[index]);
  //     }
  //   }
  //
  //   if (imagesToUpload.length) {
  //     setCurrentImages(imagesToUpload);
  //   }
  // };
  //
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
  };

  const goBack = (event) => {
    event.preventDefault();

    history.goBack();
  };

  return (
    <>
      <Helmet>
        <title>Vehicle {id ? 'edit' : 'create'} | {MAIN_TITLE}</title>
      </Helmet>
      <RouterPrompt
        when={isCreateSuccessful || (isDirty && !isSuccessful)}
        title="Leave vehicle form?"
      />
      {!isLoadingOrder && (
        <Container component="main">
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {id ? 'Edit' : 'Create'} vehicle
              </Typography>
              <Divider />
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} className="auction-data">
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="heading">
                        <Typography variant="h6" gutterBottom align="center">
                          VIN
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid xs={9} md={10}>
                          <Controller
                            name="vin"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="VIN"
                                variant="outlined"
                                fullWidth
                                required
                                onChange={(e) => field.onChange(e.target.value.trim())}
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={3} md={2}>
                          <Box textAlign="right">
                            <Button
                              onClick={populateCarInfo}
                              variant="contained"
                              color="primary"
                              style={{ height: '54px', backgroundColor: '#0093b2' }}
                              title="Find vehicle info"
                            >
                              <Search />
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="auction-info">
                        <Typography variant="h6" gutterBottom align="center">
                          Auction information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="stockNumber"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('stockNumber')}
                              label="Stock number"
                              variant="outlined"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="auction-name" variant="outlined" required>Auction name</InputLabel>
                          <Controller
                            name="auctionName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={disabledFields.includes('auctionName')}
                                labelId="auction-name"
                                label="Auction name"
                                variant="outlined"
                                fullWidth
                                required
                              >
                                {auctionsData.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>{name}</MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="auction-location" variant="outlined" required>Auction location</InputLabel>
                          <Controller
                            name="auctionLocation"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={!auctionId || disabledFields.includes('auctionLocation')}
                                labelId="auction-location"
                                label="Auction location"
                                variant="outlined"
                                fullWidth
                                required
                              >
                                {auctionLocations.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>{name}</MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="additional-info">
                        <Typography variant="h6" gutterBottom align="center">
                          Vehicle information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={disabledFields.includes('status')}
                                labelId="status"
                                label="Status"
                                variant="outlined"
                                required
                                fullWidth
                              >
                                {ORDER_STATUSES.map(({ key, label, value }) => (
                                  <MenuItem key={`status-${key}`} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="year"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('year')}
                              label="Year"
                              required
                              variant="outlined"
                              type="number"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="make"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('make')}
                              label="Make"
                              variant="outlined"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="model"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('model')}
                              label="Model"
                              variant="outlined"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="keys-present" variant="outlined" required>Keys present</InputLabel>
                          <Controller
                            name="keysPresent"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={disabledFields.includes('keysPresent')}
                                labelId="keys-present"
                                label="Keys present"
                                variant="outlined"
                                required
                                fullWidth
                              >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="user-attachment">
                    <Typography variant="h6" gutterBottom align="center">
                      Link to client
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="username" variant="outlined" required>User</InputLabel>
                      <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            disabled={disabledFields.includes('username')}
                            labelId="username"
                            label="User"
                            variant="outlined"
                            fullWidth
                            required
                          >
                            {usersData.map(({ email, firstName, lastName }) => (
                              <MenuItem key={email} value={email}>{firstName} {lastName} ({email})</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="warehouse" variant="outlined">Warehouse</InputLabel>
                      <Controller
                        name="warehouse"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            disabled={disabledFields.includes('warehouse')}
                            labelId="warehouse"
                            label="Warehouse"
                            variant="outlined"
                            fullWidth
                          >
                            <MenuItem value="">
                              None
                            </MenuItem>
                            {warehousesData.map(({ email, firstName, lastName }) => (
                              <MenuItem key={email} value={email}>{firstName} {lastName}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="shipping-details">
                        <Typography variant="h6" gutterBottom align="center">
                          Shipping details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="placeOfDelivery"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('placeOfDelivery')}
                              label="Port of destination"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                      </Grid>
                      {id && (<Grid item xs={12}>
                        <Controller
                          name="trackingNumber"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('trackingNumber')}
                              label="TrackingNumber"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>)}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} className="dates-users">
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="expected-date">
                        <Typography variant="h6" gutterBottom align="center">
                          Destination ETA
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="expectedDate"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('expectedDate')}
                              label="Expected date"
                              type="date"
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="note"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabledFields.includes('note')}
                              label="Additional notes"
                              variant="outlined"
                              multiline
                              rows={4}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {errorMessage && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="body2">
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                  {/*{id && (*/}
                  {/*  <>*/}
                  {/*    <Grid item xs={12}>*/}
                  {/*      <Grid container spacing={3}>*/}
                  {/*        <Grid item xs={12} className="gallery">*/}
                  {/*          <Grid container>*/}
                  {/*            <Grid item xs={8}>*/}
                  {/*              <Typography variant="h6" gutterBottom>*/}
                  {/*                Gallery*/}
                  {/*              </Typography>*/}
                  {/*            </Grid>*/}
                  {/*            <Grid item xs={4}>*/}
                  {/*              <Box textAlign="right">*/}
                  {/*                <Button*/}
                  {/*                  onClick={() => setIsAddImagesDialogOpen(true)}*/}
                  {/*                  variant="contained"*/}
                  {/*                  color="primary"*/}
                  {/*                >*/}
                  {/*                  Add images*/}
                  {/*                </Button>*/}
                  {/*              </Box>*/}
                  {/*            </Grid>*/}
                  {/*          </Grid>*/}
                  {/*        </Grid>*/}
                  {/*        {vehicleImages.length ? (*/}
                  {/*          vehicleImages.map((imageData, index) => (*/}
                  {/*            <Grid item xs={12} sm={6} md={3} key={`image-${index}`}>*/}
                  {/*              <div className={galleryStyles.imageWrapper}>*/}
                  {/*                <div className={galleryStyles.removeImageButton}>*/}
                  {/*                  <IconButton*/}
                  {/*                    aria-label="delete"*/}
                  {/*                    type="button"*/}
                  {/*                    className={galleryStyles.remove}*/}
                  {/*                    onClick={() => setImageToDelete(imageData?.title || imageData)}*/}
                  {/*                  >*/}
                  {/*                    <Delete />*/}
                  {/*                  </IconButton>*/}
                  {/*                </div>*/}
                  {/*                <img*/}
                  {/*                  style={{ width: '100%' }}*/}
                  {/*                  src={imageData?.url || imageData}*/}
                  {/*                  alt={`preview-${index}`}*/}
                  {/*                />*/}
                  {/*              </div>*/}
                  {/*            </Grid>*/}
                  {/*          ))*/}
                  {/*        ) : (*/}
                  {/*          <Grid item xs={12}>*/}
                  {/*            <Typography variant="body2" gutterBottom>*/}
                  {/*              No images*/}
                  {/*            </Typography>*/}
                  {/*          </Grid>*/}
                  {/*        )}*/}
                  {/*      </Grid>*/}
                  {/*    </Grid>*/}

                  {id && (
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="gallery">
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography variant="h6" gutterBottom>
                                Files
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Box textAlign="right">
                                <Button
                                  onClick={() => setIsAddFileDialogOpen(true)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Upload file
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        {vehicleFiles.length ? (
                          <List>
                            {vehicleFiles.map((fileData, index) => (
                              <ListItem key={`file-${index}`}>
                                <ListItemAvatar>
                                  <Avatar>
                                    <AttachFile />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={fileData?.displayName || fileData?.title || fileData}
                                  style={{ wordBreak: 'break-all' }}
                                >
                                </ListItemText>
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => setFileToDelete(fileData?.title || fileData)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                              No files
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container justify={history.length > 1 ? 'space-between' : 'flex-end'}
                      style={{ paddingTop: '10px' }}>
                      {history.length > 1 && (
                        <Button
                          className={styles.backButton}
                          variant="outlined"
                          onClick={goBack}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className={styles.submitButton}
                      >
                        {id ? 'Save' : 'Create vehicle'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Container>
      )}
      {/*{id && (*/}
      {/*  <>*/}
      {/*    <Dialog*/}
      {/*      open={!!imageToDelete}*/}
      {/*      onClose={() => setImageToDelete('')}*/}
      {/*    >*/}
      {/*      <DialogTitle>Delete image</DialogTitle>*/}
      {/*      <DialogContent>*/}
      {/*        <DialogContentText>*/}
      {/*          Are you sure you want to delete this image?*/}
      {/*        </DialogContentText>*/}
      {/*      </DialogContent>*/}
      {/*      <DialogActions>*/}
      {/*        <Button onClick={deleteImage} color="primary">*/}
      {/*          Confirm*/}
      {/*        </Button>*/}
      {/*        <Button onClick={() => setImageToDelete('')} color="primary">*/}
      {/*          Cancel*/}
      {/*        </Button>*/}
      {/*      </DialogActions>*/}
      {/*    </Dialog>*/}
      <Dialog
        open={!!fileToDelete}
        onClose={() => setFileToDelete('')}
      >
        <DialogTitle>Delete a file</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteFile} color="primary">
            Confirm
          </Button>
          <Button onClick={() => setFileToDelete('')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/*    <Dialog*/}
      {/*      open={isAddImageDialogOpen}*/}
      {/*      onClose={closeImageUploadDialog}*/}
      {/*      fullWidth*/}
      {/*      maxWidth="xs"*/}
      {/*    >*/}
      {/*      <DialogTitle>Add images</DialogTitle>*/}
      {/*      <DialogContent>*/}
      {/*        <DialogContentText>*/}
      {/*          <label htmlFor="contained-button-file">*/}
      {/*            <label htmlFor="btn-upload">*/}
      {/*              <input*/}
      {/*                id="btn-upload"*/}
      {/*                name="btn-upload"*/}
      {/*                style={{ display: 'none' }}*/}
      {/*                type="file"*/}
      {/*                accept="image/*"*/}
      {/*                multiple*/}
      {/*                onChange={selectImages} />*/}
      {/*              <Button*/}
      {/*                className="btn-choose"*/}
      {/*                variant="outlined"*/}
      {/*                component="span"*/}
      {/*              >*/}
      {/*                Choose Image*/}
      {/*              </Button>*/}
      {/*            </label>*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*            <Typography variant="body2" gutterBottom>*/}
      {/*              {currentImages && currentImages.length ? currentImages.map(image => (*/}
      {/*                <div key={`image-name-${image.name}`}>*/}
      {/*                  {image.name}*/}
      {/*                </div>*/}
      {/*              )) : 'No images selected'}*/}
      {/*            </Typography>*/}
      {/*            {errorMessageUpload && (*/}
      {/*              <Typography color="error" variant="body2">*/}
      {/*                {errorMessageUpload}*/}
      {/*              </Typography>*/}
      {/*            )}*/}
      {/*          </label>*/}
      {/*        </DialogContentText>*/}
      {/*      </DialogContent>*/}
      {/*      <DialogActions>*/}
      {/*        <Button onClick={() => uploadImage()} color="primary">*/}
      {/*          Save*/}
      {/*        </Button>*/}
      {/*        <Button onClick={closeImageUploadDialog} color="primary">*/}
      {/*          Cancel*/}
      {/*        </Button>*/}
      {/*      </DialogActions>*/}
      {/*    </Dialog>*/}
      <Dialog
        open={isAddFileDialogOpen}
        onClose={closeFileUploadDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Upload a file</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="status-filter" variant="outlined">Document type</InputLabel>
              <Select
                value={documentToUploadType}
                onChange={(event) => setDocumentToUploadType(event.target.value)}
                labelId="document-type"
                label="Document type"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="">
                  -- All --
                </MenuItem>
                <MenuItem value="billOfLading">
                  Bill of lading
                </MenuItem>
                <MenuItem value="billOfSales">
                  Bill of sales
                </MenuItem>
                <MenuItem value="vehicleTitle">
                  Vehicle title
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <label htmlFor="contained-button-file">
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  disabled={!documentToUploadType}
                  onChange={selectFile}
                />
                <Button
                  className="btn-choose"
                  variant="outlined"
                  component="span"
                  disabled={!documentToUploadType}
                >
                  Choose a file
                </Button>
              </label>
              <br />
              <Typography variant="body2" gutterBottom>
                {currentFile ? currentFile.name : 'No file selected'}
              </Typography>
              {errorMessageUpload && (
                <Typography color="error" variant="body2">
                  {errorMessageUpload}
                </Typography>
              )}
            </label>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => uploadFile()} color="primary">
            Save
          </Button>
          <Button onClick={closeFileUploadDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/*  </>*/}
      {/*)}*/}
      {(isLoading || isLoadingUsers || isLoadingOrder || isLoadingContainers) && <Loader />}
      <br />
    </>
  );
};

export default OrderForm;
