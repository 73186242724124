import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: 100,
    textAlign: 'center',
    padding: 20,
  },
});

const NotFound = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography color="error" variant="h4" gutterBottom>
          404 - Page Not Found!
        </Typography>
        <Typography color="textSecondary" variant="body1">
          Oops! It seems like the page you are looking for does not exist.
        </Typography>
        <Typography color="textSecondary" variant="body2">
          The requested page may have been moved or deleted.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NotFound;
