import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Chip, Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import { get } from '../../shared/api';
import { CONTAINER_STATUSES } from '../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../shared/helpers';

import styles from '../Home/Home.module.scss';

const VehicleDetails = ({ vehicleId, vehicles = [] }) => {
  const [vehicleData, setVehicleData] = useState(null);

  useEffect(() => {
    if (!vehicleId || !Array.isArray(vehicles) || vehicles.length === 0) {
      return '';
    }

    const vehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

    if (!vehicle) {
      return '';
    }

    setVehicleData(vehicle);
  }, [vehicleId, vehicles]);

  return vehicleData ? (
    <div style={{ textAlign: 'left' }}>
      <span>{vehicleData.year} {vehicleData.make}&nbsp;</span>
      (<Link
      to={`/orders/${vehicleData.id}`}
      component={RouterLink}
      target="_blank"
      color="primary"
      area-label="view"
    >
      {vehicleData.vin}
    </Link>)
    </div>
  ) : '';
};

const UserContainersList = () => {
  const [allContainers, setAllContainers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areVehiclesLoading, setAreVehiclesLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');

  const { addToast } = useToasts();

  const setFilteredOrders = (status, searchTerm) => {
    let filteredOrders = [...allContainers];

    if (typeof status === 'number' && status !== 'all') {
      filteredOrders = filteredOrders.filter((container) => container?.status === status);
    }

    if (searchTerm && searchTerm.trim()) {
      filteredOrders = filteredOrders.filter((container) => {
        return JSON.stringify(Object.values(container)).toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setContainers(filteredOrders);
  };

  const loadContainers = async () => {
    setIsLoading(true);

    await get('/containers/user')
      .then((containersData) => {
        if (Array.isArray(containersData)) {
          setAllContainers(containersData);
          setContainers(containersData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const loadVehicles = () => {
    setAreVehiclesLoading(true);

    get(`/vehicles/container/my`)
      .then((vehiclesData) => {
        if (Array.isArray(vehiclesData)) {
          setVehicles(vehiclesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setAreVehiclesLoading(false);
      });
  }

  useEffect(() => {
    loadContainers();
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allContainers.length > 0) {
      setFilteredOrders(statusFilter, searchFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter]);

  return (
    <>
      <Helmet>
        <title>My containers | {MAIN_TITLE}</title>
      </Helmet>
      <Container>
        <Paper square>
          <div className={styles.heading}>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} lg={2}>
                <Typography variant="h5">
                  My containers
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status-filter" variant="outlined">Status</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(event) => setStatusFilter(event.target.value)}
                    labelId="status-filter"
                    label="Status"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="all">
                      -- All --
                    </MenuItem>
                    {CONTAINER_STATUSES.map(({ key, label, value }) => (
                      <MenuItem key={`status-${key}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={7} lg={6}>
                <TextField
                  value={searchFilter}
                  onChange={(event) => setSearchFilter(event.target.value)}
                  label="Search"
                  variant="outlined"
                  placeholder="Search by name, shipping line, etc..."
                  fullWidth
                  autoComplete="email"
                  size="small"
                />
              </Grid>
            </Grid>
          </div>
          {(isLoading || areVehiclesLoading) && <Loader />}
          <Table
            title=""
            columns={[
              {
                title: 'Status',
                field: 'status',
                searchable: false,
                render: container => (
                  <Chip
                    label={CONTAINER_STATUSES.find((status) => status.value === container?.status)?.label || container.status || '-'}
                    color="primary"
                  />
                )
              },
              { title: 'Container number', field: 'name', render: container => <p>{container.name}</p> },
              {
                title: 'Shipping line', field: 'shippingLine', render: container => {
                  return (container.shippingLine && container.shippingLine.trackUrl && container.name)
                    ? (
                      <p>
                        <a
                          href={container.shippingLine.trackUrl.replace('{#id}', container.name)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {container.shippingLine.name}
                        </a>
                      </p>
                    )
                    : container.shippingLine?.name || '-';
                },
              },
              {
                title: 'ETA',
                field: 'expectedDate',
                render: container => (
                  <p>
                        {container.expectedDate || '-'}
                        {container.placeOfDelivery ? (
                            <>
                                <br />
                                <strong style={{ fontWeight: 600 }}>{container.placeOfDelivery}</strong>
                            </>
                        ) : null}
                  </p>
                ),
              },
              {
                title: 'Documents',
                field: 'filesResponse',
                sorting: false,
                searchable: false,
                render: order => order.filesResponse
                  ? (
                    <>
                      <span>Bill of lading:&nbsp;</span>
                      <IconButton
                        component="a"
                        href={order.filesResponse?.url || order.filesResponse}
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        area-label="download"
                        size="small"
                        title={order.filesResponse?.title || order.filesResponse}
                      >
                        <AttachFile fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <Typography color="error" variant="body1">
                      N/A
                    </Typography>
                  )
              },
              {
                align: 'center',
                title: 'Loaded vehicles',
                field: 'vehicleIds',
                width: '300px',
                render: container => (
                  <div>
                    <Typography variant="body2">
                      {areVehiclesLoading ? (
                        <span>Loading...</span>
                      ) : (
                        <>
                          <strong>
                            Total: {container.vehicleIds?.length || 0}
                          </strong>
                          <br />
                          {container.vehicleIds?.length > 0 ? (
                            <div style={{ paddingLeft: '10px' }}>
                              {container.vehicleIds.map((vehicleId) => (
                                <VehicleDetails
                                  key={`${vehicleId}_${container.id}`}
                                  vehicleId={vehicleId}
                                  vehicles={vehicles}
                                />
                              ))}
                            </div>
                          ) : ''
                          }
                        </>
                      )}
                    </Typography>
                  </div>
                )
              },
            ]}
            data={containers}
            options={
              {
                search: false,
                paging: false,
                toolbar: false,
                maxBodyHeight: 'calc(100vh - 270px)',
              }
            }
          />
        </Paper>
      </Container>
    </>
  );
};

export default UserContainersList;
