export const HOME_ROUTE = '/';

export const LOGIN_ROUTE = '/login';

export const ORDER_STATUSES = [
  { label: 'New', key: 'New', value: 7 },
  { label: 'Dispatched', key: 'Dispatched', value: 0 },
  { label: 'At terminal', key: 'AtTerminal', value: 1 },
  { label: 'Loaded', key: 'LoadedInContainer', value: 2 },
  { label: 'Delivered', key: 'Delivered', value: 3 },
  // { label: 'Customs', key: 'Customs', value: 4 },
  // { label: 'Dispatched to final destination', key: 'DispatchedToFinalDestination', value: 5 },
  { label: 'Archived', key: 'Archived', value: 6 },
  { label: 'Booked', key: 'Booked', value: 8 },
];

export const CONTAINER_STATUSES = [
  { label: 'Loaded', key: 'Loaded', value: 0 },
  { label: 'Delivered', key: 'Delivered', value: 1 },
];

export const ROLE_ADMIN = 'Admin';
export const ROLE_USER = 'User';
export const ROLE_WAREHOUSE = 'Warehouse';

export const ROLES_MAP = {
  ADMIN: ROLE_ADMIN,
  USER: ROLE_USER,
  WAREHOUSE: ROLE_WAREHOUSE,
};
