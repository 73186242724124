import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useToasts } from 'react-toast-notifications';
import { Container, TextField, Button, Typography, Modal } from '@material-ui/core';
import { QrReader } from 'react-qr-reader';
import styles from './QRScannedPage.module.scss';
import { MAIN_TITLE } from '../../assets/styles/variables';
import { put } from '../../shared/api';
import { getErrorMessage } from '../../shared/helpers';

const QRScannedPage = () => {
  const [vinNumbers, setVinNumbers] = useState([]);
  const [vinDestinations, setVinDestinations] = useState([]);
  const [makes, setMakes] = useState([]);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [containerNumber, setContainerNumber] = useState('');
  const { addToast } = useToasts();

  const handleAtTerminalClick = () => {
    sendRequest('atTerminal');
  };

  const handleBookedClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setContainerNumber(''); // Reset container number when modal closes
  };

  const handleModalSubmit = () => {
    // Perform any validation if needed for container number here

    // Send request with containerNumber included
    sendRequest('booked', containerNumber);
    setIsModalOpen(false);
    setContainerNumber('');
  };

  const handleScan = (data) => {
    if (data) {
      const splittedData = data.text.split(',');
      const vin = splittedData[0];
      const destination = splittedData[1];
      const make = splittedData[2];
      setVinNumbers((prevVin) => [...prevVin, vin]);
      setVinDestinations((prevDestinations) => [...prevDestinations, destination]);
      setMakes((prevMake) => [...prevMake, make]);
    }
  };

  const handleRemoveVin = (index) => {
    setVinNumbers((prevVin) => prevVin.filter((_, i) => i !== index));
    setVinDestinations((prevDestinations) => prevDestinations.filter((_, i) => i !== index));
  };

  const handleError = (error) => {
    console.error(error);
    addToast('Error scanning QR code', { appearance: 'error' });
  };

  const handleClear = () => {
    setVinNumbers([]);
    setVinDestinations([]);
    setIsCameraOpen(false);
  };

  const sendRequest = (action, containerNumber) => {
    const requestData = {
      action,
      vinNumbers,
      containerNumber
    };

    put('/vehicles/qr/decode', requestData)
      .then(() => {
        addToast(`Scanned vehicles successfully changed to ${action}!`, { appearance: 'success' });
        handleClear();
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const handleScanQrButtonClick = useCallback(() => {
    setIsCameraOpen(true);
  }, []);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>QR Scanned Page | {MAIN_TITLE}</title>
      </Helmet>
      <Container>
        <form className={styles.form}>
          <Typography variant="h4" className={styles.title} color="primary" gutterBottom>
            Vehicle QR Code Scanner Page
          </Typography>
          <TextField
            label="Scan VIN Code"
            multiline
            rows={4}
            variant="outlined"
            value={vinNumbers.join('\n')}
            disabled
          />
          <br />
          {vinNumbers.map((vin, index) => (
            <div key={index} className={styles.vinListItem}>
              <div className={styles.vinInfoContainer}>
                <TextField
                  label={`VIN ${index + 1}`}
                  variant="outlined"
                  value={vin}
                  disabled
                  className={styles.widerTextField}
                />
                &nbsp;
                <TextField
                  label="Destination"
                  variant="outlined"
                  value={vinDestinations[index] || ''}
                  disabled
                />
                <TextField
                  label="Make"
                  variant="outlined"
                  value={makes[index] || ''}
                  disabled
                />
              </div>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                className={styles.removeButton}
                onClick={() => handleRemoveVin(index)}
              >
                Remove
              </Button>
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleScanQrButtonClick}
            className={styles.qrReaderButton}
            disabled={isCameraOpen}
          >
            Scan QR Code
          </Button>
          <Modal open={isModalOpen} onClose={handleModalClose}>
            <div className={styles.modalContainer}>
              <Typography className={styles.modalTitle} variant="h6" gutterBottom>
                Enter Container Number
              </Typography>
              <TextField
                label="Container Number"
                variant="outlined"
                value={containerNumber}
                onChange={(e) => setContainerNumber(e.target.value)}
                className={styles.modalTextField}
              />
              <div className={styles.modalButtonContainer}>
                <Button variant="contained" color="primary" onClick={handleModalSubmit}>
                  Submit
                </Button>
                <Button variant="contained" color="default" onClick={handleModalClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <br />
          {isCameraOpen && (
            <>
              <QrReader
                delay={1000}
                onError={handleError}
                onScan={handleScan}
                onResult={handleScan}
                className={styles.qrReader}
                constraints={{facingMode : 'environment'}}
              />
              <Button
                variant="contained"
                color="default"
                onClick={handleCloseCamera}
                className={styles.qrReaderButton}
              >
                Close Camera
              </Button>
            </>
          )}
          <Button
            variant="contained"
            color="default"
            onClick={handleClear}
            className={styles.qrReaderButton}
          >
            Clear VIN Numbers
          </Button>
          <div className={styles.buttonContainer}>
            <Button variant="contained" color="primary" onClick={handleAtTerminalClick}>
              Change to At Terminal
            </Button>
            <Button variant="contained" color="secondary" onClick={handleBookedClick}>
              Change to Booked
            </Button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default QRScannedPage;
