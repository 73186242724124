import React from 'react';

import imageLoader from '../../assets/images/image-loader.gif'
import styles from './ImageLoader.module.scss';

const ImageLoader = () => (
  <div className={styles.vehicleMainImageWrapper}>
    <div className={styles.vehicleMainImage}>
      <img className={styles.imageLoaderImage} src={imageLoader} alt="Loading..." />
    </div>
  </div>
);

export default ImageLoader;
