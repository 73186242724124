import {
  AppBar,
  Button,
  Container, Divider,
  Drawer,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import { MenuOpen } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';

import { LOGIN_ROUTE } from '../../shared/contants';
import { deleteCookie, JWT_TOKEN_COOKIE_NAME } from '../../shared/web-storage';
import { logOutUserAction } from '../../store/actions/auth.actions';
import { selectUser } from '../../store/selectors/auth.selectors';

import logo from '../../assets/images/heading.png'
import { GENERAL_INFO_WEBSITE, MAIN_TITLE } from '../../assets/styles/variables';
import styles from './Header.module.scss';

const leftRoutes = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'General Info',
    href: GENERAL_INFO_WEBSITE,
    external: true,
  },
];

const authRoutes = [
  {
    label: 'Register',
    href: '/register',
  },
  {
    label: 'Log in',
    href: '/login',
  },
];

const protectedRoutes = {
  Admin: [
    {
      label: 'Profile',
      href: '/profile',
      inHamburger: true,
    },
    {
      label: 'Add vehicle',
      href: '/orders/create',
      inHamburger: false,
    },
    {
      label: 'Vehicles',
      href: '/orders',
      inHamburger: false,
    },
    {
      label: 'Containers',
      href: '/containers',
      inHamburger: false,
    },
    {
      label: 'Users',
      href: '/users',
      inHamburger: true,
    },
    {
      label: 'Configuration',
      href: '/configuration',
      inHamburger: true,
    },
    {
      label: 'QR Scan',
      href: '/qr-scanner',
      inHamburger: true,
    },
  ],
  User: [
    {
      label: 'Profile',
      href: '/profile',
      inHamburger: true,
    },
    // {
    //   label: 'Request vehicle',
    //   href: '/orders/request',
    //   inHamburger: false,
    // },
    {
      label: 'My orders',
      href: '/orders',
      inHamburger: false,
    },
    {
      label: 'My containers',
      href: '/my-containers',
      inHamburger: false,
    },
  ],
  Warehouse: [
    {
      label: 'Profile',
      href: '/profile',
      inHamburger: true,
    },
    {
      label: 'QR Scan',
      href: '/qr-scanner',
      inHamburger: false,
    },
   // {
   //   label: 'My orders',
   //   href: '/orders',
   //   inHamburger: false,
   // },
   // {
   //   label: 'Containers',
   //   href: '/containers',
   //  inHamburger: false,
   //},
  ],
};

const Header = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const loggedUser = useSelector(selectUser);
  const history = useHistory();

  let userRoutes = [];

  if (loggedUser) {
    userRoutes = protectedRoutes[loggedUser.role || 'User'];
  }

  const dispatch = useDispatch();

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(logOutUserAction());
    setAnchorEl(null);
    deleteCookie(JWT_TOKEN_COOKIE_NAME);
    history.push(LOGIN_ROUTE);
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return setIsMobileView(window.innerWidth < 900);
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => (
    <div className={styles.desktopHeader}>
      <Toolbar>
        <Container>
          <div className={styles.HeaderWrapper}>
            <Link
              component={RouterLink}
              to={'/'}
              color="inherit"
              style={{ textDecoration: 'none' }}
            >
              <img className={styles.Logo} src={logo} alt={MAIN_TITLE} />
            </Link>
            <nav className={styles.Navigation}>
              {getMenuButtons()}
            </nav>
          </div>
        </Container>
      </Toolbar>
    </div>
  );

  const displayMobile = () => {
    const handleDrawerOpen = () => setIsDrawerOpen(true);
    const handleDrawerClose = () => setIsDrawerOpen(false);

    return (
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          aria-haspopup="true"
          onClick={handleDrawerOpen}
        >
          <MenuOpen />
        </IconButton>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          onClick={handleDrawerClose}
        >
          <div className={styles.Drawer}>
            {getDrawerChoices()}
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => (
    <>
      {[
        ...leftRoutes,
        ...(loggedUser ? userRoutes : authRoutes)
      ].map(({ label, href }) => (
        <Link
          component={RouterLink}
          to={href}
          color="inherit"
          style={{ textDecoration: 'none' }}
          key={label}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      ))}
      {loggedUser && (
        <>
          <Divider />
          <MenuItem onClick={() => logOut()}>Log out</MenuItem>
        </>
      )}
    </>
  );

  const getMenuButtons = () => {
    let routesLeft = leftRoutes;
    const routesInMenu = userRoutes.filter(({ inHamburger }) => !!inHamburger);

    if (loggedUser) {
      routesLeft = leftRoutes.concat([...userRoutes.filter(({ inHamburger }) => !inHamburger)]);
    }

    return (
      <>
        <div>
          {routesLeft.map(({ label, href, external }) => external ? (
            <Button
              key={label}
              component="a"
              href={href.includes('http') ? href : `http://${href}`}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              className={styles.HeaderLink}
            >
              {label}
            </Button>
          ) : (
            <Button
              key={label}
              to={href}
              component={RouterLink}
              color="inherit"
              className={styles.HeaderLink}
            >
              {label}
            </Button>
          ))}
        </div>
        <div>
          {loggedUser
            ? (
              <>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleUserMenuOpen}
                >
                  Welcome, {loggedUser.email.split('@')[0]}!
                  &nbsp;
                  <MenuOpen />
                </Button>
                <Menu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleUserMenuClose}
                  onClick={handleUserMenuClose}
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {routesInMenu.map(({ label, href }) => (
                    <Link
                      component={RouterLink}
                      to={href}
                      color="inherit"
                      key={label}
                    >
                      <MenuItem>
                        <ListItemText primary={label} />
                      </MenuItem>
                    </Link>
                  ))}
                  {loggedUser && <Divider />}
                  {loggedUser && (
                    <MenuItem>
                      <ListItemText primary="Log out" onClick={() => logOut()} />
                    </MenuItem>
                  )}
                </Menu>
              </>
            )
            : authRoutes.map(({ label, href }) => (
              <Button
                key={label}
                to={href}
                component={RouterLink}
                color="inherit"
              >
                {label}
              </Button>
            ))}
        </div>
      </>
    );
  }

  return (
    <AppBar className={styles.Header}>
      {isMobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
};

export default Header;
