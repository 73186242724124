import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR_BACKGROUND } from '../../../../assets/styles/variables';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AdditionalDetails = ({ userName, phoneNumber, isAdmin = false, warehouse }) => (
  <TableContainer component={Paper}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Additional details</StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Attached user
          </StyledTableCell>
          <StyledTableCell>
            {userName ? <a href={`mailto:${userName}`}>{userName}</a> : '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            User's phone
          </StyledTableCell>
          <StyledTableCell>
            {phoneNumber ? <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> : '-'}
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default AdditionalDetails;
