import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Container, Typography } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CropFreeIcon from '@material-ui/icons/CropFree';

import { MAIN_TITLE } from '../../assets/styles/variables';
import VimModal from '../../components/VimModal';
import { LOGIN_ROUTE } from '../../shared/contants';

import logo from '../../assets/images/heading.png'
import styles from './Home.module.scss';
import { selectIsUserAuthenticated, selectUser } from '../../store/selectors/auth.selectors';
import { ROLE_WAREHOUSE } from '../../shared/contants';

const Home = () => {
  const history = useHistory();
  const [isVimModalOpen, setIsVimModalOpen] = useState(false);
  const isAuthenticated = useSelector(selectIsUserAuthenticated);
  const currentUser = useSelector(selectUser);

  const goToLoginPage = () => {
    history.push(LOGIN_ROUTE);
  };

  return (
    <div>
      <Container className={styles.Container}>
        <div className={styles.Heading}>
          <img className={styles.HeadingImage} src={logo} alt={MAIN_TITLE} />
        </div>
        <Typography variant="subtitle1" gutterBottom className={styles.Subtitle}>
          ImpEx has never been easier.
        </Typography> 
        <br />
        <div className={styles.action}>
        {isAuthenticated ? (
            <div>
              {currentUser && currentUser.role === ROLE_WAREHOUSE ? (
                <Button
                  size="small"
                  className={styles.VimButton}
                  onClick={() => history.push('/qr-scanner')} // Navigate to '/qr-scanner' page
                >
                  <CropFreeIcon/> &nbsp; Scan Vehicle
                </Button>
              ) : (
                <Button
                  size="small"
                  className={styles.VimButton}
                  onClick={() => setIsVimModalOpen(true)}
                >
                  <MyLocationIcon />&nbsp;Track Vehicle
                </Button>
              )}
            </div>
          ) : (
            <div>
              <Button
                size="small"
                className={styles.VimButton}
                onClick={goToLoginPage}
              >
                <Typography />&nbsp;Log in and start tracking
              </Button>
            </div>
          )}
          </div>
      </Container>
      <VimModal open={isVimModalOpen} handleClose={() => setIsVimModalOpen(false)} />
    </div>
  );
};

export default Home;
