import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, AttachFile, Delete, Edit } from '@material-ui/icons';

import { MAIN_TITLE } from '../../../../assets/styles/variables';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/Table';
import TransferList from '../../../../components/TransferList';
import { del, get, post, put } from '../../../../shared/api';
import { CONTAINER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../../../shared/helpers';
import { selectUser } from '../../../../store/selectors/auth.selectors';

import styles from '../../../Home/Home.module.scss';

const VehicleDetails = ({ vehicleId, vehicles = [] }) => {
  const [vehicleData, setVehicleData] = useState(null);

  useEffect(() => {
    if (!vehicleId || !Array.isArray(vehicles) || vehicles.length === 0) {
      return '';
    }

    const vehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

    if (!vehicle) {
      return '';
    }

    setVehicleData(vehicle);
  }, [vehicleId, vehicles]);

  return vehicleData ? (
    <div style={{ textAlign: 'left' }}>
      <span> <strong style={{ fontWeight: 600 }}>{vehicleData.year}</strong> {vehicleData.model}&nbsp;</span>
      (<Link
        to={`/orders/${vehicleData.id}`}
        component={RouterLink}
        target="_blank"
        color="primary"
        area-label="view"
      >
        {vehicleData.vin}
      </Link>)
    </div>
  ) : '';
};

const ContainersList = (status) => {
  const PORT_OF_DESTINATIONS = [
    {
      name: 'None',
      value: '',
    },
    {
      name: 'Antwerpen',
      value: 'Antwerpen',
    },
    {
      name: 'Rotterdam',
      value: 'Rotterdam',
    },
    {
      name: 'Bremerhaven',
      value: 'Bremerhaven',
    },
    {
      name: 'Klaipeda',
      value: 'Klaipeda',
    },
    {
      name: 'Poti',
      value: 'Poti',
    },
    {
      name: 'Aqaba',
      value: 'Aqaba',
    },
    {
      name: 'Armenia',
      value: 'Armenia',
    },
    {
      name: 'Costa Rica',
      value: 'Costa Rica',
    },
    {
      name: 'Mersin',
      value: 'Mersin',
    },
    {
      name: 'Jebel Ali',
      value: 'Jebel Ali',
    },
    {
      name: 'Felixstowe',
      value: 'Felixstowe',
    },
    {
      name: 'Gdansk',
      value: 'Gdansk',
    },
    {
      name: 'Valencia',
      value: 'Valencia',
    },
  ];
  const [allContainers, setAllContainers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [shippingLines, setShippingLines] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [containerToEdit, setContainerToEdit] = useState(null);
  const [containerToDelete, setContainerToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [areVehiclesLoading, setAreVehiclesLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');
  const [currentContainerLoadedVehicles, setCurrentContainerLoadedVehicles] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const currentUser = useSelector(selectUser);
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();

  const setFilteredOrders = (status, searchTerm) => {
    let filteredOrders = [...allContainers];

    if (typeof status === 'number' && status !== 'all') {
      filteredOrders = filteredOrders.filter((container) => container?.status === status);
    }

    if (searchTerm && searchTerm.trim()) {
      filteredOrders = filteredOrders.filter((container) => {
        return JSON.stringify(Object.values(container)).toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setContainers(filteredOrders);
  };

  const loadContainers = async () => {
    setIsLoading(true);

    await get('/shipping-lines')
      .then((data) => {
        if (Array.isArray(data)) {
          data.map(containerData => ({
            ...containerData,
            expectedDate: getDateFormValue(data.expectedDate),
          }))

          setShippingLines(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });

    let ordersUrl = '/containers/my';
    if (currentUser.role === ROLE_ADMIN) {
      console.log(status)
      ordersUrl = `/containers?status=${status}`;
    }

    await get(ordersUrl)
      .then((containersData) => {
        if (Array.isArray(containersData)) {
          setAllContainers(containersData);
          setContainers(containersData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const loadVehicles = () => {
    setAreVehiclesLoading(true);

    let vehiclesUrl = `/vehicles/container/my/${currentUser.email}`;
    if (currentUser.role === ROLE_ADMIN) {
      vehiclesUrl = '/vehicles/container';
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      vehiclesUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    get(vehiclesUrl)
      .then((vehiclesData) => {
        if (Array.isArray(vehiclesData)) {
          setVehicles(vehiclesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setAreVehiclesLoading(false);
      });
  }

  useEffect(() => {
    loadContainers();
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allContainers.length > 0) {
      setFilteredOrders(statusFilter, searchFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter]);

  const openEditDialog = (containerData) => {
    setCurrentContainerLoadedVehicles(containerData.vehicleIds);
    setContainerToEdit(containerData);
    reset({
      ...containerData,
      shippingLine: containerData?.shippingLine?.id || '',
      status: CONTAINER_STATUSES.find(status => status.value === containerData?.status)?.value,
    },
    );
  };

  const closeEditDialog = () => {
    setCurrentFile(null);
    setCurrentContainerLoadedVehicles(null);
    setContainerToEdit(null);
    reset({ name: '', expectedDate: '', shippingLine: '', status: '' });
  };

  const editContainer = async (data) => {
    setIsEditLoading(true);

    const {
      id,
      expectedDate,
      name,
      placeOfDelivery,
      shippingLine,
      status,
    } = data;

    let formData = {
      name,
      status,
      vehicleIds: currentContainerLoadedVehicles,
      username: currentUser.email,
      placeOfDelivery,
    };

    if (shippingLine) {
      formData = {
        ...formData,
        shippingLineId: shippingLine,
      };
    }

    if (expectedDate) {
      formData = {
        ...formData,
        expectedDate,
      };
    }

    if (id) {
      await put(`/containers/${id}`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Container updated successfully!',
              { appearance: 'success' }
            );

            loadContainers();
            loadVehicles();
            setSearchFilter('');
            setStatusFilter('all');
            closeEditDialog();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      await post(`/containers`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Container created successfully!',
              { appearance: 'success' }
            );

            if (currentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', currentFile);

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              post(
                `/pdf/upload/multiple`,
                fileUploadFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              )
                .then((response) => {
                  if (response) {
                    setCurrentFile(null);
                    addToast('Bill of lading successfully uploaded!', { appearance: 'success' });

                    loadContainers();
                    loadVehicles();
                    closeEditDialog();
                    setSearchFilter('');
                    setStatusFilter('all');
                  }
                })
                .catch((error) => {
                  console.log(error);
                  addToast(getErrorMessage(error), { appearance: 'error' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              loadContainers();
              loadVehicles();
              closeEditDialog();
              setSearchFilter('');
              setStatusFilter('all');
            }
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    }
  };

  const setLoadedVehicles = (vehicleVins) => {
    setCurrentContainerLoadedVehicles(
      vehicles
        .filter(vehicle => vehicleVins.includes(vehicle.vin))
        .map(vehicle => vehicle.id)
    );
  };

  const deleteContainer = () => {
    setIsDeleteLoading(true);

    del(`/containers/${containerToDelete.id}`)
      .then((response) => {
        if (response) {
          addToast('Container deleted successfully!', { appearance: 'success' });
          setContainerToDelete(null);
          loadContainers();
          loadVehicles();
          setSearchFilter('');
          setStatusFilter('all');
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  // File upload
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
  };

  return (
    <>
      <Helmet>
        <title>Containers | {MAIN_TITLE}</title>
      </Helmet>
      <div className={styles.heading}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} lg={2}>
            <Typography variant="h5">
              Containers
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="status-filter" variant="outlined">Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(event) => setStatusFilter(event.target.value)}
                labelId="status-filter"
                label="Status"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="all">
                  -- All --
                </MenuItem>
                {CONTAINER_STATUSES.map(({ key, label, value }) => (
                  <MenuItem key={`status-${key}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={6}>
            <TextField
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              label="Search"
              variant="outlined"
              placeholder="Search by name, shipping line, etc..."
              fullWidth
              autoComplete="email"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={styles.createButton}
              onClick={() => {
                openEditDialog({
                  name: '',
                  shippingLine: '',
                  status: 0,
                  vehicleIds: [],
                });
              }}
            >
              <Add fontSize="small" />&nbsp;Create
            </Button>
          </Grid>
        </Grid>
      </div>
      {(isLoading || isEditLoading || isDeleteLoading || areVehiclesLoading) && <Loader />}
      <Table
        title=""
        columns={[
          {
            title: 'Status',
            field: 'status',
            searchable: false,
            render: container => (
              <Chip
                label={CONTAINER_STATUSES.find((status) => status.value === container?.status)?.label || container.status || '-'}
                color="primary"
              />
            )
          },
          { title: 'Container number', field: 'name', render: container => <p>{container.name}</p> },
          {
            title: 'Shipping line', field: 'shippingLine', render: container => {
              return (container.shippingLine && container.shippingLine.trackUrl && container.name)
                ? (
                  <p>
                    <a
                      href={container.shippingLine.trackUrl.replace('{#id}', container.name)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {container.shippingLine.name}
                    </a>
                  </p>
                )
                : container.shippingLine?.name || '-';
            },
          },
          {
            title: 'ETA',
            field: 'expectedDate',
            render: container => (
              <p>
                {container.expectedDate || '-'}
                {container.placeOfDelivery ? (
                  <>
                    <br />
                    <strong style={{ fontWeight: 600 }}>{container.placeOfDelivery}</strong>
                  </>
                ) : null}
              </p>
            ),
          },
          {
            title: 'Documents',
            field: 'filesResponse',
            sorting: false,
            searchable: false,
            render: order => order.filesResponse
              ? (
                <>
                  <span>Bill of lading:&nbsp;</span>
                  <IconButton
                    component="a"
                    href={order.filesResponse?.url || order.filesResponse}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    area-label="download"
                    size="small"
                    title={order.filesResponse?.title || order.filesResponse}
                  >
                    <AttachFile fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <Typography color="error" variant="body1">
                  N/A
                </Typography>
              )
          },
          {
            align: 'center',
            title: 'Loaded vehicles',
            field: 'vehicleIds',
            width: '300px',
            render: container => (
              <div>
                <Typography variant="body2">
                  {areVehiclesLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <>
                      <strong>
                        Total: {container.vehicleIds?.length || 0}
                      </strong>
                      <br />
                      {container.vehicleIds?.length > 0 ? (
                        <div style={{ paddingLeft: '10px' }}>
                          {container.vehicleIds.map((vehicleId) => (
                            <VehicleDetails
                              key={`${vehicleId}_${container.id}`}
                              vehicleId={vehicleId}
                              vehicles={vehicles}
                            />
                          ))}
                        </div>
                      ) : ''
                      }
                    </>
                  )}
                </Typography>
              </div>
            )
          },
          {
            align: 'right',
            searchable: false,
            sorting: false,
            title: '',
            render: container => (
              <>
                <IconButton
                  color="primary"
                  area-label="edit"
                  onClick={() => openEditDialog(container)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  color="primary"
                  area-label="delete"
                  onClick={() => {
                    setContainerToDelete(container)
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            ),
          }
        ]}
        data={containers}
        options={
          {
            search: false,
            paging: false,
            toolbar: false,
            maxBodyHeight: 'calc(100vh - 270px)',
          }
        }
      />
      <Dialog
        open={!!containerToEdit}
        onClose={closeEditDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(editContainer)}>
          <DialogTitle>{containerToEdit?.id ? 'Edit' : 'Create new'} container</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Container number"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth size="small">
                  <InputLabel id="placeOfDelivery" variant="outlined">Port of Destination</InputLabel>
                  <Controller
                    name="placeOfDelivery"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="placeOfDelivery"
                        label="Port of Destination"
                        variant="outlined"
                        fullWidth
                      >
                        {PORT_OF_DESTINATIONS.map(({ name, value }, index) => (
                          <MenuItem key={`${value}-${index}`} value={value}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="status"
                        label="Status"
                        variant="outlined"
                        required
                        fullWidth
                      >
                        {CONTAINER_STATUSES.map(({ key, label, value }) => (
                          <MenuItem key={`status-${key}`} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="shippingLine" variant="outlined">Shipping line</InputLabel>
                  <Controller
                    name="shippingLine"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="shippingLine"
                        label="Shipping line"
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem key="shipping-line-none" value="">-- Clear --</MenuItem>
                        {shippingLines.map(({ id, name, trackUrl }) => (
                          <MenuItem key={`shipping-line-${id}`} value={id}>
                            {name} ({trackUrl})
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="expectedDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Expected date"
                      type="date"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {containerToEdit && (
                <Grid item xs={12} md={3}>
                  {containerToEdit.id ? (
                    <div style={{ padding: '6px 0 0 4px' }}>
                      <span>Bill of lading:&nbsp;</span>
                      {containerToEdit.filesResponse ? (
                        <IconButton
                          component="a"
                          href={containerToEdit.filesResponse?.url || containerToEdit.filesResponse}
                          rel="noopener noreferrer"
                          target="_blank"
                          color="primary"
                          area-label="download"
                          size="small"
                          title={containerToEdit.filesResponse?.title || containerToEdit.filesResponse}
                        >
                          <AttachFile fontSize="small" />
                        </IconButton>
                      ) : <span>None</span>}
                    </div>
                  ) : (
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={selectFile}
                      />
                      <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span"
                        fullWidth
                        style={{ minHeight: '37px' }}
                      >
                        {currentFile
                          ? (
                            <span style={{
                              width: '100%',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}>
                              BoL: {currentFile.name}
                            </span>
                          )
                          : 'Upload "Bill of lading"'}
                      </Button>
                    </label>
                  )}
                </Grid>
              )}
              {(containerToEdit && containerToEdit.vehicleIds && vehicles.length) ? (
                <Grid item xs={12}>
                  <TransferList
                    leftLabel="Vehicles"
                    rightLabel="Loaded vehicles"
                    initialLeftItems={vehicles.filter(v => !(v.container && v.container.name)).map(v => v.vin)}
                    initialRightItems={vehicles.filter(v => containerToEdit.vehicleIds.includes(v.id)).map(v => v.vin)}
                    handleChanged={setLoadedVehicles}
                  />
                </Grid>
              ) : null}
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={closeEditDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!containerToDelete}
        keepMounted
        onClose={() => setContainerToDelete(false)}
      >
        <DialogTitle>Delete container</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this container?
            {containerToDelete && (
              <>
                <br />
                Container number: <strong>{containerToDelete.name}</strong>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteContainer} color="primary">
            Confirm
          </Button>
          <Button onClick={() => setContainerToDelete(null)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContainersList;
