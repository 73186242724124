import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';

import AdditionalDetails from './components/AdditionalDetails';
import AuctionDetails from './components/AuctionDetails';
import CarDetails from './components/CarDetails';
import DocumentsDetails from './components/DocumentsDetails';
import Loader from '../../components/Loader';
import ImageLoader from '../../components/ImageLoader';
import ShippingDetails from './components/ShippingDetails';
import { del, get } from '../../shared/api';
import { ORDER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE, ROLE_USER } from '../../shared/contants';
import { getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import noImage from '../../assets/images/no-image.jpeg';
import { MAIN_TITLE } from '../../assets/styles/variables';

import styles from './OrderDetails.module.scss';

const OrderDetails = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState({});
  //const vehicleImages = [];
  const [vehicleImages, setVehicleImages] = useState([]);
  const [vehicleFiles, setVehicleFiles] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [filesUrl, setFilesUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const loggedUser = useSelector(selectUser);
  const history = useHistory();
  const { addToast } = useToasts();

  const downloadGalleryImages = async () => {
    setIsLoading(true);
    const urls = vehicleImages.map(data => data.url);
    const names = vehicleImages.map(data => data.title);
    try {
      let zipName;
      const imageUrls = urls.map((url, index) => ({
        url,
        name: `${names[index]}`,
      }));
      try {
        zipName = `${orderData.vin}_photos`;
      } catch (error) {
        zipName = 'download.zip';
      }

      const imagePromises = [];
      const zip = new JSZip()

      imageUrls.forEach(({ url }) => {
        imagePromises.push(axios(url, { responseType: 'blob' }));
      });

      const images = await Promise.all(imagePromises);

      images.forEach((image, index) => {
        zip.file(
          imageUrls[index] ? imageUrls[index].name : `img${index}.jpg`,
          image.data,
          { binary: true }
        );
      });

      const zippedFile = await zip.generateAsync({ type: 'blob' });
      saveAs(zippedFile, zipName);
    } catch (error) {
      console.error(error);
      addToast('Cannot download images', { appearance: 'error' });
    }
    setIsLoading(false);
  };

  const openDeleteConfirmation = (event) => {
    event.preventDefault();
    setIsDeleteModalOpen(true);
  };

  const deleteOrder = () => {
    setIsDeleteLoading(true);

    del(`/vehicles/${id}`)
      .then((response) => {
        if (response) {
          addToast('Order deleted successfully!', { appearance: 'success' });
          history.push('/orders');
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const goBack = (event) => {
    event.preventDefault();

    history.goBack();
  };

  const loadOrderDetails = () => {
    setIsLoading(true);

    get(`/vehicles/id/${id}`)
      .then((data) => {
        setOrderData(data);

        // Files URL to drive
        if (data && data.vin) {

          get(`/images/get/${data.vin}?email=${data.userName}`)
            .then((images) => {
              if (images && Array.isArray(images)) {
                setVehicleImages(images.filter(image => !!image));
              }
            })
            .catch((error) => {
              addToast(getErrorMessage(error), { appearance: 'error' });
            })
            .finally(() => {
              setIsImageLoading(false);
            });

          if (
            loggedUser?.role === ROLE_ADMIN
            || loggedUser?.role === ROLE_WAREHOUSE
            || loggedUser?.email === data.userName
          ) {
            // Attachments
            get(`/pdf/get/${id}`)
              .then((files) => {
                if (files && files.billOfLading && files.billOfSale && files.vehicleTitles) {
                  setVehicleFiles(files);
                } else if (files && Array.isArray(files)) {
                  setVehicleFiles(files.filter(file => !!file));
                }
              })
              .catch((error) => {
                addToast(getErrorMessage(error), { appearance: 'error' });
              });
          }
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      loadOrderDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Order details | {MAIN_TITLE}</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} lg={9}>
                      <Typography variant="h5" gutterBottom>
                        <span
                          className={styles.heading}>{orderData.year} {orderData.make} {orderData.model} {orderData.vin}</span>
                        &nbsp;
                        <span className={styles.status}>
                          (Status: {ORDER_STATUSES.find((status) => status.key === orderData?.status)?.label || orderData.status || '-'})
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} lg={3}>
                      {(loggedUser?.role === ROLE_ADMIN || loggedUser?.role === ROLE_WAREHOUSE) && (
                        <Box textAlign="right" className={styles.buttons}>
                          {filesUrl && (
                            <>
                              <Button
                                href={filesUrl}
                                component="a"
                                color="primary"
                                variant="contained"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ backgroundColor: '#0093b2' }}
                              >
                                Files
                              </Button>
                              &nbsp;
                            </>
                          )}
                          <Button
                            to={`/orders/${id}/edit`}
                            component={RouterLink}
                            color="primary"
                            variant="contained"
                          >
                            Edit
                          </Button>
                          &nbsp;
                          <Button
                            onClick={openDeleteConfirmation}
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: '#e2172f' }}
                          >
                            Delete
                          </Button>
                          {history.length > 1 && (
                            <>
                              &nbsp;
                              <Button variant="outlined" onClick={goBack}>
                                Back
                              </Button>
                            </>
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                  <br />
                </Grid>
                <Grid item xs={12} md={6}>
                  {isImageLoading ? (
                    <ImageLoader />
                  ) : (
                    <div className={styles.vehicleMainImageWrapper}>
                      <img
                        className={styles.vehicleMainImage}
                        src={vehicleImages.length ? vehicleImages[1]?.url || vehicleImages[1] : noImage}
                        alt={`${orderData.make} - ${orderData.model}`}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <CarDetails
                    vin={orderData.vin}
                    model={orderData.model}
                    make={orderData.make}
                    year={orderData.year}
                    keysPresent={orderData.keysPresent}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ShippingDetails
                    placeOfDelivery={orderData.placeOfDelivery}
                    containerName={orderData.container?.name || '-'}
                    containerShippingLine={orderData.container?.shippingLine || {}}
                    terminal={orderData.terminal?.name}
                    expectedDate={orderData.expectedDate}
                    containerExpectedDate={orderData.container?.expectedDate || ''}
                    containerPortOfDestination= {orderData.container?.placeOfDelivery || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AuctionDetails
                    auctionLocation={orderData.auctionLocation?.name}
                    auction={orderData.auctionName}
                    stockNumber={orderData.stockNumber}
                  />
                </Grid>
                {(
                  loggedUser?.role === ROLE_ADMIN
                  || loggedUser?.role === ROLE_WAREHOUSE
                  || loggedUser?.email === orderData.userName
                ) && (
                    <Grid item xs={12} md={6}>
                      <DocumentsDetails
                        billOfLading={vehicleFiles?.billOfLading}
                        billOfSales={vehicleFiles?.billOfSale}
                        vehicleTitle={vehicleFiles?.vehicleTitles}
                        trackingNumber={orderData?.trackingNumber}
                      />
                    </Grid>
                  )}
                {(
                  loggedUser?.role === ROLE_ADMIN
                  || loggedUser?.role === ROLE_WAREHOUSE
                  || loggedUser?.role === ROLE_USER
                ) && (
                    <Grid item xs={12} md={6}>
                      <AdditionalDetails
                        isAdmin={loggedUser?.role === ROLE_ADMIN}
                        userName={orderData.userName}
                        phoneNumber={orderData.phoneNumber}
                      />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Gallery
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {isImageLoading ? (
                        <ImageLoader />
                      ) : (
                        vehicleImages.length ? (
                          <div>
                            <ImageGallery
                              autoPlay={false}
                              showPlayButton={false}
                              items={vehicleImages.map(image => ({
                                original: image?.url || image,
                                thumbnail: image?.url || image,
                              }))}
                            />
                            <div>
                              <br />
                              <Button
                                size="small"
                                onClick={downloadGalleryImages}
                                variant="contained"
                                color="primary"
                              >
                                Download all images
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Typography variant="body2" gutterBottom>
                            No images
                          </Typography>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Notes
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>{orderData.note || '-'}</Typography>
                      <br />
                    </Grid>
                  </Grid>
                </Grid>
                {/*{loggedUser && (*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <Grid container>*/}
                {/*            <Grid item xs={12}>*/}
                {/*                <Typography variant="h6" gutterBottom>*/}
                {/*                    Attachments*/}
                {/*                </Typography>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12}>*/}
                {/*                {vehicleFiles.length ? vehicleFiles.map((fileData, index) => (*/}
                {/*                    <div key={`file-${index}`}>*/}
                {/*                        <Button*/}
                {/*                            key={`file-${index}`}*/}
                {/*                            component="a"*/}
                {/*                            href={fileData?.url || fileData}*/}
                {/*                            rel="noopener noreferrer"*/}
                {/*                            target="_blank"*/}
                {/*                            color="primary"*/}
                {/*                            variant="outlined"*/}
                {/*                        >*/}
                {/*                            Download attachment {index + 1}*/}
                {/*                        </Button>*/}
                {/*                    </div>*/}
                {/*                )) : (*/}
                {/*                    <Typography variant="body2" gutterBottom>*/}
                {/*                        No attachments*/}
                {/*                    </Typography>*/}
                {/*                )}*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*)}*/}
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={isDeleteModalOpen}
            keepMounted
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DialogTitle>Delete order</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this order?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteOrder} color="primary">
                Confirm
              </Button>
              <Button onClick={() => setIsDeleteModalOpen(false)} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {isDeleteLoading && <Loader />}
        </Container>
      )}
      <br />
    </>
  );
};

export default OrderDetails;
